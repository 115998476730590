import { ReplaySubject, BehaviorSubject } from "rxjs"
import { raiPlayerEvents } from "./player-events"

export const TRACKING_PAGE_EVENT = 'rai-tracker:tracking-page';
export const TRACKING_USER_EVENT = 'rai-tracker:tracking-user'

let enqueueMediaSubject = new ReplaySubject<unknown>();
let enqueuePageSubject = new ReplaySubject<unknown>();
let enqueueUserSubject = new ReplaySubject<unknown>();
let enqueueOnAirSubject = new ReplaySubject<unknown>();

const policyActivatorSubject = new BehaviorSubject(false);
const privacyPolicyAcceptedSubject = new BehaviorSubject(false)

const advSubject = new BehaviorSubject(false);

const docReady = (fn: (this: Document) => void) => {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(fn, 0);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

export class TrackEnqueue {
  constructor() {
    window.addEventListener(TRACKING_PAGE_EVENT, (event) => {
      const _event = event as CustomEvent<{ pageInfo: unknown }>;
      if (_event.detail.pageInfo) {
        enqueuePageSubject.next(_event.detail.pageInfo);
      }
    });

    window.addEventListener(TRACKING_USER_EVENT, (event) => {
      const _event = event as CustomEvent<{ track_info: unknown }>;
      if (_event.detail.track_info) {
        enqueueUserSubject.next(_event.detail.track_info);
      }
    });

    (function enqueuePlayerEvents() {
      const allEvents: string[] = [];

      (function flatEventObj(eventObject: Record<string, unknown>) {
        for (const key in eventObject) {
          if (typeof eventObject[key] === "object" && eventObject[key] !== null) {
            flatEventObj(eventObject[key] as Record<string, unknown>);
          } else {
            allEvents.push(eventObject[key] as string);
          }
        }
      })(raiPlayerEvents.media);

      allEvents.forEach(eventName => {
        window.addEventListener(eventName, (event) => {
          enqueueMediaSubject.next(event);
        });
      });
    })();

    if (this.GDPRManager) {
      this.initPolicyActivator();
    } else {
      window.addEventListener("policyManager:defined", this.initPolicyActivator.bind(this), { once: true });
      console.warn("defined listener for GDPR Manager not found");
    }

    // Dispatch dell'evento quando TrackEnqueue è pronto
    window.dispatchEvent(new CustomEvent('trackEnqueue:ready'));
  }

  private get GDPRManager() {
    return window.Rai?.PolicyManager;
  }

  private initPolicyActivator() {
    const verifyPolicyIsAccepted = () => {
      if (this.GDPRManager?.hasTrackerConsents()) {
        policyActivatorSubject.next(true)
      }
      privacyPolicyAcceptedSubject.next(true);
    };
    if (this.GDPRManager) {
      this.GDPRManager.events$.subscribe(ev => {
        if (ev.type === this.GDPRManager?.events["POLICY_MANAGER_DEFINED_EVENT"]) {
          verifyPolicyIsAccepted();
        }
      })
    } else {
      window.addEventListener("policyManager:defined", verifyPolicyIsAccepted, { once: true });
    }
  }

  get mediaQueue() {
    return enqueueMediaSubject.asObservable()
  }

  get pageQueue() {
    return enqueuePageSubject.asObservable()
  }

  get onAirQueue() {
    return enqueueOnAirSubject.asObservable()
  }

  setPageQueueEvent(data: unknown) {
    enqueuePageSubject.next(data)
  }

  setOnAirQueueEvent(data: unknown) {
    enqueueOnAirSubject.next(data);
  }

  get userQueue() {
    return enqueueUserSubject.asObservable()
  }

  setUserQueueEvent(data: unknown) {
    enqueueUserSubject.next(data);
  }

  reInitialize() {
    // Svuota tutte le code
    enqueuePageSubject.complete();
    enqueueUserSubject.complete();
    enqueueOnAirSubject.complete();
    enqueueMediaSubject.complete();

    // Sostituisci i Subject globali
    enqueuePageSubject = new ReplaySubject<unknown>();;
    enqueueUserSubject = new ReplaySubject<unknown>();;
    enqueueOnAirSubject = new ReplaySubject<unknown>();;
    enqueueMediaSubject = new ReplaySubject<unknown>();;

    // Reinizializza l'istanza
    window.Rai = window.Rai || {};
    window.Rai._trackEnqueue = new TrackEnqueue();
    return window.Rai._trackEnqueue;
  }

  get policyActivator() {
    return policyActivatorSubject.asObservable()
  }

  get privacyPolicyActivator() {
    return privacyPolicyAcceptedSubject.asObservable()
  }

  get advActivator() {
    return advSubject.asObservable()
  }
}

// Inizializzazione globale
window.Rai = window.Rai ?? {} as Rai;
window.Rai._trackEnqueue = window.Rai._trackEnqueue ?? new TrackEnqueue();

// Esportiamo anche l'istanza per l'import dinamico
export default window.Rai._trackEnqueue;
