export const raiPlayerEvents = {
  openPlayerRequest: 'rai:player-openPlayerRequest',
  changeVideoRequest: 'rai:player-changeVideoRequest',
  firstPlay: 'player:rai:firstplay',
  opened: 'rai:player-opened',
  closed: 'rai:player-closed',
  connected: 'rai:player-connected',
  ready: 'rai:player-ready',
  urlChanged: 'rai:player-url-changed',
  videoItemJsonReady: 'rai:player-video-json-ready',
  media: {
    CREATION: 'CREATION_EVENT',
    READY: 'READY_EVENT',
    REPLAY: 'REPLAY',
    PLAY: 'PLAY_MEDIA',
    RATECHANGE: 'RATECHANGE',
    PLAYBACK_STARTED: 'PLAYBACK_STARTED',
    FIRSTPLAY: 'tracking:firstplay',
    AUDIO_CHANGED: 'AUDIO_CHANGED',
    SUBTITLE_CHANGED: 'SUBTITLE_CHANGED',
    DOWNLOAD_DATA_START: 'DOWNLOAD_DATA_START',
    DOWNLOAD_DATA_END: 'DOWNLOAD_DATA_END',
    NETWORK_ERROR: 'NETWORK_ERROR',
    BUTTON_BACK_CLICKED: 'BUTTON_BACK_CLICKED',
    ENDED: 'ENDED_EVENT',
    ERROR: 'ERROR_EVENT',
    LOADED: 'LOADDATA_EVENT',
    LOADMETADATA: 'LOADED_METADATA_EVENT',
    TIMEUPDATE: 'TIMEUPDATE_EVENT',
    USERACTIVE: 'USERACTIVE_EVENT',
    USERINACTIVE: 'USERINACTIVE_EVENT',
    VOLUMECHANGE: 'VOLUME_CHANGED',
    MUTE_CHANGE: 'MUTE_CHANGE',
    FULLSCREEN_EVENT: 'FULLSCREEN_CHANGE',
    CONTAINER_READY: 'CONTAINER_READY',
    MEDIAPOLIS_PARSE_END: 'MEDIAPOLIS_PARSE_END',
    MEDIAPOLIS_PARSE_ERROR: 'MEDIAPOLIS_PARSE_ERROR',
    NOT_SUPPORTED_MEDIA_TYPE: 'NOT_SUPPORTED_MEDIA_TYPE',
    AUTOPLAY_BLOCKED: 'AUTOPLAY_BLOCKED',
    HEARTBEAT: 'HEARTBEAT',
    PERCENTILE: 'PERCENTILE',
    BUFFER_STALLED: 'BUFFER_STALLED',
    BUFFER_STALLED_ERROR: 'BUFFER_STALLED_ERROR',
    BUFFER_FULL: 'BUFFER_FULL',
    DECILE: 'DECILE',
    FIRST_QUARTILE: 'FIRST_QUARTILE',
    MIDPOINT: 'MIDPOINT',
    THIRD_QUARTILE: 'THIRD_QUARTILE',
    SEEK: 'SEEK_EVENT',
    SEEKING: 'SEEKING_EVENT',
    SEEK_START: 'SEEK_START',
    SEEK_END: 'SEEK_END',
    RESTART_BUTTON_CLICKED: 'RESTART_BUTTON_CLICKED',
    LOAD_START: 'LOADSTART_EVENT',
    PAUSE: 'PAUSE_EVENT',
    PLAYING: 'PLAYING_EVENTS',
    BUFFERING_END: 'buffering_end',
    BUFFERING_STARTED: 'buffering_started',
    STOP: 'STOP_EVENT',
    PLAYLIST_END: 'PLAYLIST_END',
    RESIZE: 'PLAYER_RESIZE',
    PIP_START: 'PIP_START',
    PIP_STOP: 'PIP_STOP',
    LICENSE_REQUEST_ATTEMPT: 'LICENSE_REQUEST_ATTEMPT',
    DRM_LICENSE_USABLE: 'DRM_LICENSE_USABLE',
    DRM_LICENSE_OUTPUT_RESTRICTED: 'DRM_LICENSE_OUTPUT_RESTRICTED',
    DRM_LICENSE_STATUS_INTERNAL_ERROR: 'DRM_LICENSE_STATUS_INTERNAL_ERROR',
    KEYSTATUSCHANGE: 'KEY_STATUS_CHANGE',
    CHROMECAST_START: 'CHROMECAST_START',
    CHROMECAST_STOP: 'CHROMECAST_STOP',
    GO_NEXT_EPISODE: 'GO_NEXT_EPISODE',
    QUALITY_CHANGE: 'QUALITY_CHANGE',
    DISPOSE: 'PLAYER_DISPOSE',
    DISPOSED: 'PLAYER_DISPOSED',
    READY_FOR_POSTROLL: 'READY_FOR_POSTROLL',
    READY_FOR_PREROLL: 'READY_FOR_PREROLL',
    BUTTON_PANLEL_CLICKED: 'BUTTON_PANLEL_CLICKED',
    TRACKS_CREATED: 'TRACKS_CREATED',
    EPISODE_ITEM_CLICK: 'EPISODE_ITEM_CLICK',
    ONAIR_ITEM_CLICK: 'ONAIR_ITEM_CLICK',
    LINK_PROGRAM_BUTTON_CLICKED: 'LINK_PROGRAM_BUTTON_CLICKED',
    PROGRAM_INFO_UPDATED: 'PROGRAM_INFO_UPDATED',
    WATCH_ON_CLICKED: 'WATCH_ON_CLICKED',
    TECH_REF_READY: 'TECH_REF_READY',
    ADS: {
      ADSERROR: 'ADSERROR',
      ADS_REQUEST: 'adsrequest',
      ADBUFFERING: 'adBuffering',
      ADBUFFERINGEND: 'adBufferingEnd',
      ADS_BIND_ERROR: 'ADS_BIND_ERROR',
      CONTENT_RESUME_REQUESTED: 'contentResumeRequested',
      CONTENT_PAUSE_REQUESTED: 'contentPauseRequested',
      CLICK: 'adsclick',
      DURATION_CHANGE: 'durationChange',
      EXPANDED_CHANGED: 'expandedChanged',
      STARTED: 'start',
      IMPRESSION: 'impression',
      PAUSED: 'pause',
      RESUMED: 'resume',
      AD_PROGRESS: 'adProgress',
      FIRST_QUARTILE: 'firstquartile',
      MIDPOINT: 'midpoint',
      THIRD_QUARTILE: 'thirdquartile',
      COMPLETE: 'complete',
      USER_CLOSE: 'userClose',
      LINEAR_CHANGED: 'linearChanged',
      LOADED: 'loaded',
      AD_CAN_PLAY: 'adCanPlay',
      AD_METADATA: 'adMetadata',
      AD_BREAK_READY: 'adBreakReady',
      INTERACTION: 'interaction',
      ALL_ADS_COMPLETED: 'allAdsCompleted',
      SKIPPED: 'skip',
      SKIPPABLE_STATE_CHANGED: 'skippableStateChanged',
      LOG: 'log',
      VIEWABLE_IMPRESSION: 'viewable_impression',
      VOLUME_CHANGED: 'volumeChange',
      VOLUME_MUTED: 'mute',
      AD_BLOCK_DETECTED: 'adBlockDetected',
    },
  },
};
